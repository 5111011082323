import { FormGroup, ButtonGroup, Button, Checkbox, FormHelperText, Dialog, Grid, LinearProgress } from '@mui/material';
import axios from 'axios';
import { useState, useEffect, FC, PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IConsents } from '../../lib/interfaces/interfaces';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styles from './style.module.scss';

type Props = {
    onError: () => void;
    onConsentsAccepted: (code?: string) => void;
};

const Consents: FC<PropsWithChildren<Props>> = ({ onConsentsAccepted, onError }) => {
    const { control, handleSubmit } = useForm();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [consents, setConsents] = useState<IConsents>();
    const [file, setFile] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageToShow, setPageToShow] = useState(1);
    const [open, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages);
    };

    useEffect(() => {
        if (!consents) {
            setShowLoader(true);
            axios
                .get(`${process.env.REACT_APP_TOS_SERVICE}/api/v1/public/consents`, { withCredentials: true })
                .then((response) => {
                    console.log(response);
                    setShowLoader(false);
                    setConsents(response.data.consents);
                })
                .catch(() => {
                    setShowLoader(false);
                    onError();
                });
        }
    }, [consents, onError]);

    const submitForm = (data) => {
        let acceptedConsents = Object.keys(data)
            .map((consentId) => (data[consentId] ? consentId : null))
            .filter((consentId) => consentId != null);

        const payload = { documentIds: acceptedConsents };
        setShowLoader(true);
        axios
            .post(`${process.env.REACT_APP_TOS_SERVICE}/api/v1/public/consents`, payload, { withCredentials: true })
            .then(() => {
                setShowLoader(false);
                onConsentsAccepted();
            })
            .catch((error) => {
                setShowLoader(false);
                onError();
            });
    };

    return (
        <div className={styles.root}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>CONSENT(S)</div>
                {showLoader && <LinearProgress color="secondary" />}
                {consents && (
                    <div className={styles.consentsBody}>
                        <FormGroup>
                            {consents.map((consent, i) => (
                                <Controller
                                    key={i}
                                    name={consent.documentId}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className={styles.inputMargin}>
                                            <Grid container alignItems="center">
                                                <Checkbox value={value} onChange={onChange} />
                                                <label
                                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setShowLoader(true);
                                                        axios
                                                            .get(`${process.env.REACT_APP_TOS_SERVICE}/api/v1/public/consents/${consent.documentId}`, {
                                                                withCredentials: true,
                                                            })
                                                            .then((response) => {
                                                                setShowLoader(false);
                                                                setFile(`data:application/pdf;base64,${response.data.pdfBase64Content}`);
                                                                setIsOpen(true);
                                                            })
                                                            .catch((error) => {
                                                                setShowLoader(false);
                                                                onError();
                                                            });
                                                    }}>
                                                    {consent.label}
                                                </label>
                                            </Grid>
                                            <FormHelperText className={styles.errorText}>{error?.message}</FormHelperText>
                                        </div>
                                    )}
                                    rules={{
                                        required: {
                                            value: consent.required,
                                            message: '*obavezno',
                                        },
                                    }}
                                />
                            ))}
                        </FormGroup>
                        <Dialog onClose={handleClose} open={open} maxWidth="md" className={styles.dialog}>
                            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageToShow} />
                            </Document>
                            <Grid container alignItems="center" className={styles.buttonGroupPages}>
                                <Button className={styles.buttonsPages} disabled={pageToShow === 1} onClick={() => setPageToShow(pageToShow - 1)}>
                                    -
                                </Button>
                                {pageToShow} od {numPages}
                                <Button className={styles.buttonsPages} disabled={pageToShow === numPages} onClick={() => setPageToShow(pageToShow + 1)}>
                                    +
                                </Button>
                            </Grid>
                        </Dialog>
                        <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                            <Button onClick={handleSubmit(submitForm)}>Pošalji</Button>
                        </ButtonGroup>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Consents;
