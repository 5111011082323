import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import CookieConsent from 'react-cookie-consent';
import { CookiesIcon } from '../../lib/style/icons/icons';
import AmlForm from '../amlForm';
import Notification from '../notification';
import Consents from '../consents';
import Finish from '../finish';
import IdentyumIdentifications from '../identyumIdentifications';
import Loader from '../loader';
import LoanConfigurator from '../loanConfigurator';
import Psd2Form from '../psd2/psd2Form';
import { INotificationStatus } from '../../lib/interfaces/interfaces';
import styles from './style.module.scss';
import UpdateLoan from '../updateLoan';

interface ISessionState {
    nextComponent: IStepState;
    preconditionFailedType?: PreconditionFailedType;
    components: Array<IStepState>;
}

interface IStepState {
    componentType: ComponentType;
    ordinal: number;
    finished: boolean;
}

enum PreconditionFailedType {
    BLACK_LISTS,
    AML,
}

enum ComponentType {
    CONSENTS = 'CONSENTS',
    IDENTYUM_IDENTIFICATION = 'IDENTYUM_IDENTIFICATION',
    PSD2 = 'PSD2',
    LOAN_CONFIGURATOR = 'LOAN_CONFIGURATOR',
    UPDATE_LOAN = 'UPDATE_LOAN',
    AML = 'AML',
    FINISH = 'FINISH',
}

const Home = () => {
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [notificationStatus, setNotificationStatus] = useState<INotificationStatus>({ show: false });
    const [sessionState, setSessionState] = useState<ISessionState>();

    const handleError = useCallback((status?: number) => {
        if (status === 412) {
            setNotificationStatus({
                show: true,
                message:
                    'Poštovani, u ovom trenutku nažalost ne zadovoljavate uvjete za odobrenje nenamjenskog kredita KentBanke digitalnim putem. Molimo Vas posjetite našu najbližu poslovnicu kako biste provjerili dodatne mogućnosti.',
                showRetryButton: false,
            });
        } else
            setNotificationStatus({
                show: true,
                message: 'Dogodila se neočekivana greška.',
                showRetryButton: true,
            });
    }, []);

    const handleNextStep = useCallback(
        (code?: string | null) => {
            setShowLoader(true);
            axios
                .get(`${process.env.REACT_APP_SESSION_MANAGER}/api/v1/sessions`, {
                    withCredentials: true,
                    params: { code },
                })
                .then((response) => {
                    setShowLoader(false);

                    if (response.data.preconditionFailedType) {
                        setNotificationStatus({
                            show: true,
                            message: 'Molimo obratite se u najbližu poslovnicu!',
                            showRetryButton: false,
                        });
                        return;
                    }

                    setNotificationStatus({
                        show: false,
                        message: '',
                        showRetryButton: false,
                    });
                    setSessionState(response.data);
                })
                .catch(() => {
                    handleError();
                    setShowLoader(false);
                });
        },
        [handleError]
    );

    useEffect(() => {
        if (showLoader && !sessionState) {
            let urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            handleNextStep(code);
        }
    }, [handleNextStep, sessionState, showLoader]);

    const renderingFunction = () => {
        const nextComponent = sessionState?.nextComponent.componentType;

        if (!nextComponent) {
            return;
        }

        if (nextComponent === ComponentType.CONSENTS) {
            return <Consents onConsentsAccepted={handleNextStep} onError={handleError} />;
        }
        if (nextComponent === ComponentType.IDENTYUM_IDENTIFICATION) {
            return <IdentyumIdentifications onError={handleError} />;
        }
        if (nextComponent === ComponentType.PSD2) {
            return <Psd2Form onSuccess={handleNextStep} onError={handleError} />;
        }
        if (nextComponent === ComponentType.LOAN_CONFIGURATOR) {
            return <LoanConfigurator onSuccess={handleNextStep} onError={handleError} />;
        }
        if (nextComponent === ComponentType.UPDATE_LOAN) {
            return <UpdateLoan onSuccess={handleNextStep} onError={handleError} />;
        }
        if (nextComponent === ComponentType.AML) {
            return <AmlForm onSuccess={handleNextStep} onError={handleError} />;
        }
        return <Finish />;
    };

    const handleTryAgain = () => {
        handleNextStep();
    };

    return (
        <div className={styles.root}>
            <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.root}>
                {(sessionState || notificationStatus.show) && (
                    <AppBar position="static" color="secondary">
                        <Toolbar>
                            <Typography className="title">KENT Loan Portal</Typography>
                        </Toolbar>
                    </AppBar>
                )}
                {notificationStatus.show ? <Notification status={notificationStatus} onTryAgain={handleTryAgain} /> : <>{renderingFunction()}</>}
                <Loader appear={showLoader} />
            </Grid>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="cookieConsent"
                contentClasses={styles.cookieContainer}
                overlay={true}
                style={{
                    background: '#3f51b5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                buttonClasses={styles.cookieButton}
                expires={150}>
                <CookiesIcon />
                <span style={{ marginLeft: '10px' }}>This website uses cookies to enhance the user experience.</span>
            </CookieConsent>
        </div>
    );
};

export default Home;
