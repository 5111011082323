import { Stepper, Step, StepLabel, FormGroup, FormHelperText, FormControl, MenuItem, TextField, ButtonGroup, Button } from '@mui/material';
import { Questionnaire } from 'kent-aml-questionnaire-api';
import { FC, PropsWithChildren, useState, useEffect } from 'react';
import { amlApiController } from '../../api/apis';
import Loader from '../loader';
import { Controller, useForm } from 'react-hook-form';
import styles from './style.module.scss';

type Props = {
    onError: (status?: number) => void;
    onSuccess: () => void;
};

const AmlForm: FC<PropsWithChildren<Props>> = ({ onError, onSuccess }) => {
    const { control, handleSubmit, watch } = useForm();
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [amlData, setAmlData] = useState<Questionnaire>();
    const [activeStep, setActiveStep] = useState(0);
    const ime = watch('ime');
    const usvojeime = watch('usvojeime');
    const ostalo = watch('ostalo');
    const svrha = watch('svrha');

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (!amlData) {
            amlApiController
                .getAml()
                .then((response) => {
                    setShowLoader(false);
                    setAmlData(response.data);
                })
                .catch(() => {
                    setShowLoader(false);
                    onError();
                });
        }
    }, [onError, amlData, ime, usvojeime]);

    const submitForm = (data: any) => {
        data.osobaRc = usvojeime === '1' ? ime : data.osobaRc;
        setShowLoader(true);
        amlApiController
            .sendAml({
                answers: Object.entries(data as Questionnaire).map(([key, value]) => {
                    return { field: key, value: value };
                }),
            })
            .then(() => {
                setShowLoader(false);
                onSuccess();
            })
            .catch((err) => {
                setShowLoader(false);
                onError(err.response.status);
            });
    };

    const defaultChoice = (question: any) => {
        if (question.name === 'polizl') {
            return question.options[1].value;
        }
        if (question.name === 'usvojeime') {
            return question.options[0].value;
        }
        if (question.name === 'drzavaRod') {
            return question.options.find((opt: any) => opt.value === 'HR').value;
        }
        if (question.name === 'fatcaUSAdrz') {
            return question.options[1].value;
        }
        if (question.name === 'fatcaZelenaKarta') {
            return question.options[1].value;
        }
        if (question.name === 'fatcaUSAporez') {
            return question.options[1].value;
        } else return question.answer;
    };

    return (
        <div className={styles.root}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>
                    <span>AML Questionnaire</span>
                </div>
                <Loader appear={showLoader} />
                {amlData && (
                    <div className={styles.body}>
                        <div className={styles.upperDiv}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {amlData.categories.map((category, i) => (
                                    <Step key={category.caption}>
                                        <StepLabel>{category.caption}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <FormGroup className={styles.form}>
                                {activeStep === 0 &&
                                    amlData.categories[0].questions.map((question, i) => (
                                        <Controller
                                            key={i}
                                            name={question.name}
                                            defaultValue={question.answer}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <>
                                                    <FormHelperText className={styles.label}>
                                                        {question.name === 'drzavljanstvo2' ? 'Dvojno državljanstvo' : question.caption}
                                                    </FormHelperText>
                                                    {question.choose ? (
                                                        <FormControl variant="outlined" className={styles.inputMargin}>
                                                            <TextField
                                                                select
                                                                defaultValue={question.answer}
                                                                name={question.name}
                                                                disabled={question.readonly}
                                                                onChange={onChange}
                                                                error={!!error}
                                                                value={value ? value : ''}>
                                                                {question.options.map((option, i) => (
                                                                    <MenuItem key={i} value={option.value}>
                                                                        {option.displayValue}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </FormControl>
                                                    ) : (
                                                        <TextField
                                                            className={styles.inputMargin}
                                                            variant="outlined"
                                                            value={value ? value : ''}
                                                            onChange={onChange}
                                                            error={!!error}
                                                            helperText={error ? error.message : null}
                                                            InputProps={{ readOnly: question.readonly }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            rules={{
                                                required: { value: question.required, message: question.choose ? 'Potrebno odabrati.' : 'Potrebno unijeti.' },
                                            }}
                                        />
                                    ))}
                                {activeStep === 1 &&
                                    amlData.categories[1].questions
                                        .filter((question) => {
                                            if (
                                                question.name !== 'osobaRc' &&
                                                question.name !== 'prebivalisteRc' &&
                                                question.name !== 'datumRodRc' &&
                                                question.name !== 'JMBGRc' &&
                                                question.name !== 'oibRc' &&
                                                question.name !== 'vrstaidRc' &&
                                                question.name !== 'brojidRc' &&
                                                question.name !== 'izdavateljRc'
                                            ) {
                                                if (question.name === 'ostaliprihodi' && ostalo !== '1') {
                                                    return false;
                                                }
                                                if (question.name === 'svrhaDet' && svrha !== '5') {
                                                    return false;
                                                }
                                                return true;
                                            } else return false;
                                        })
                                        .map((question, i) => (
                                            <Controller
                                                key={i}
                                                name={question.name}
                                                defaultValue={() => defaultChoice(question)}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <>
                                                        <FormHelperText className={styles.label}>{question.caption}</FormHelperText>
                                                        {question.choose ? (
                                                            <FormControl variant="outlined" className={styles.inputMargin}>
                                                                <TextField
                                                                    select
                                                                    defaultValue={question.answer}
                                                                    name={question.name}
                                                                    disabled={question.readonly}
                                                                    onChange={onChange}
                                                                    error={!!error}
                                                                    value={value ? value : ''}>
                                                                    {question.options.map((option, i) => (
                                                                        <MenuItem key={i} value={option.value}>
                                                                            {option.displayValue}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </FormControl>
                                                        ) : (
                                                            <TextField
                                                                className={styles.inputMargin}
                                                                variant="outlined"
                                                                value={question.name === 'osobaRc' && usvojeime === '1' ? ime : value}
                                                                onChange={onChange}
                                                                error={!!error}
                                                                helperText={error ? error.message : null}
                                                                InputProps={{ readOnly: question.readonly }}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                                rules={{
                                                    required: {
                                                        value: question.required,
                                                        message: question.choose ? 'Potrebno odabrati.' : 'Potrebno unijeti.',
                                                    },
                                                }}
                                            />
                                        ))}
                                {activeStep === 2 &&
                                    amlData.categories[2].questions
                                        .filter((question) => question.name !== 'fatcaTIN')
                                        .map((question, i) => (
                                            <Controller
                                                key={i}
                                                name={question.name}
                                                defaultValue={() => defaultChoice(question)}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <>
                                                        <FormHelperText className={styles.label}>{question.caption}</FormHelperText>
                                                        {question.choose ? (
                                                            <FormControl variant="outlined" className={styles.inputMargin}>
                                                                <TextField
                                                                    select
                                                                    defaultValue={question.answer}
                                                                    name={question.name}
                                                                    disabled={question.readonly}
                                                                    onChange={onChange}
                                                                    error={!!error}
                                                                    value={value ? value : ''}>
                                                                    {question.options.map((option, i) => (
                                                                        <MenuItem key={i} value={option.value}>
                                                                            {option.displayValue}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </FormControl>
                                                        ) : (
                                                            <TextField
                                                                className={styles.inputMargin}
                                                                variant="outlined"
                                                                value={value ? value : ''}
                                                                onChange={onChange}
                                                                error={!!error}
                                                                helperText={error ? error.message : null}
                                                                InputProps={{ readOnly: question.readonly }}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                                rules={{
                                                    required: {
                                                        value: question.required,
                                                        message: question.choose ? 'Potrebno odabrati.' : 'Potrebno unijeti.',
                                                    },
                                                }}
                                            />
                                        ))}
                            </FormGroup>
                        </div>
                        <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                            {activeStep !== 0 && <Button onClick={handleBack}>Natrag</Button>}
                            {activeStep !== amlData.categories.length - 1 && <Button onClick={handleSubmit(handleNext)}>Dalje</Button>}
                            {activeStep === amlData.categories.length - 1 && <Button onClick={handleSubmit(submitForm)}>Pošalji</Button>}
                        </ButtonGroup>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AmlForm;
