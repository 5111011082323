import { Button, ButtonGroup, Checkbox, FormGroup, FormHelperText, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Slider, { SliderProps } from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';
import Loader from '../loader';
import styles from './style.module.scss';
import { IResponse } from '../../lib/interfaces/interfaces';

type Props = {
    onSuccess: () => void;
    onError: () => void;
};

const SuccessSlider = styled(Slider)<SliderProps>(() => ({
    color: '#987c64',
    '& .MuiSlider-thumb': {
        '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${alpha('#987c64', 0.16)}`,
        },
        '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${alpha('#987c64', 0.16)}`,
        },
    },
}));

const UpdateLoan: FC<PropsWithChildren<Props>> = ({ onSuccess, onError }) => {
    const { control, handleSubmit, watch } = useForm();
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [response, setResponse] = useState<IResponse>();
    const watchAmount = watch('amount');
    const watchInstallments = watch('repaymentDeadline');

    useEffect(() => {
        if (!response) {
            axios
                .get('https://apis.kent.identyum.com/uwt-service/api/v1/public/loan', {
                    withCredentials: true,
                })
                .then((response) => {
                    setResponse(response.data);
                    setShowLoader(false);
                })
                .catch(() => {
                    setShowLoader(false);
                    onError();
                });
        }
    }, [response, onError]);

    const calculateMonthlyInstallment = (loan: number, interest: number, installmentsNr: number) => {
        const monthInterest = interest / 12 / 100;
        return loan * ((monthInterest * (1 + monthInterest) ** installmentsNr) / ((1 + monthInterest) ** installmentsNr - 1));
    };

    const interest = response?.apiProduct.interestRates?.find((currency) => currency.currency === 'EUR');
    const isBankClient = response?.loanDetails.isConsumer;
    const monthlyInstallment = isBankClient
        ? (calculateMonthlyInstallment(
              (watchAmount as number) ?? interest?.minFinancingAmount,
              (interest?.ktaWithStatusInBankTo as number) ?? 0,
              (watchInstallments as number) ?? response?.apiProduct.minFundingDeadline
          ).toFixed(2) as unknown as number)
        : (calculateMonthlyInstallment(
              (watchAmount as number) ?? interest?.minFinancingAmount,
              (interest?.ktaWithoutStatusInBankTo as number) ?? 0,
              (watchInstallments as number) ?? response?.apiProduct.minFundingDeadline
          ).toFixed(2) as unknown as number);

    const submitForm = (data) => {
        data.principalPeriodCode = response?.loanDetails.principalPeriodCode;
        data.repaymentDay = response?.loanDetails.repaymentDay;
        data.currency = response?.loanDetails.currency;
        data.interestRateTypeId = response?.loanDetails.interestRateTypeId;
        data.productId = response?.loanDetails.productId;
        setShowLoader(true);
        axios
            .put('https://apis.kent.identyum.com/uwt-service/api/v1/public/loan', data, {
                withCredentials: true,
            })
            .then(() => {
                setShowLoader(false);
                onSuccess();
            })
            .catch(() => {
                setShowLoader(false);
                onError();
            });
    };

    return (
        <div className={styles.root}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>
                    <span>Loan Update</span>
                </div>
                <Loader appear={showLoader} />
                <div className={styles.body}>
                    {response && response.maxAnnuityAmount === null && (
                        <Typography variant="h5" align="center" color="red">
                            Molimo obratite se u najbližu poslovnicu!
                        </Typography>
                    )}
                    {response && response.maxAnnuityAmount !== null && (
                        <>
                            <FormGroup>
                                <Controller
                                    name="amount"
                                    control={control}
                                    defaultValue={interest?.minFinancingAmount}
                                    render={({ field: { onChange, value } }) => (
                                        <div className={styles.sliderDivs}>
                                            <span className={styles.sliderTextOne}>Iznos</span>
                                            <SuccessSlider
                                                className={styles.slider}
                                                value={value as number}
                                                defaultValue={interest?.minFinancingAmount}
                                                max={interest?.maxFinancingAmount}
                                                min={interest?.minFinancingAmount}
                                                step={100}
                                                onChange={(_event: any, value) => onChange(value as number)}
                                                valueLabelDisplay="auto"
                                            />
                                            <span>{watchAmount ?? interest?.minFinancingAmount} EUR</span>
                                        </div>
                                    )}
                                    rules={{ required: true }}
                                />
                                <Controller
                                    name="repaymentDeadline"
                                    control={control}
                                    defaultValue={response?.apiProduct.minFundingDeadline}
                                    render={({ field: { onChange, value } }) => (
                                        <div className={styles.sliderDivs}>
                                            <span className={styles.sliderTextOne}>Rate</span>
                                            <SuccessSlider
                                                className={styles.slider}
                                                value={value as number}
                                                defaultValue={response?.apiProduct.minFundingDeadline}
                                                max={response?.apiProduct.maxFundingDeadline}
                                                min={response?.apiProduct.minFundingDeadline}
                                                step={1}
                                                onChange={(_event: any, value) => onChange(value as number)}
                                                valueLabelDisplay="auto"
                                            />
                                            <span>{watchInstallments ?? response?.apiProduct.minFundingDeadline} mj.</span>
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="setReccuringPayment"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div>
                                            <Grid container alignItems="center">
                                                <Checkbox checked={value} onChange={onChange} />
                                                <label>Otvoriti trajni nalog</label>
                                            </Grid>
                                            <FormHelperText className={styles.errorText}>{error?.message}</FormHelperText>
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="receiveSalary"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div>
                                            <Grid container alignItems="center">
                                                <Checkbox checked={value} onChange={onChange} />
                                                <label>Otvoriti tekući račun</label>
                                            </Grid>
                                            <FormHelperText className={styles.errorText}>{error?.message}</FormHelperText>
                                        </div>
                                    )}
                                />
                                <Typography style={{ marginBottom: 20 }} variant="caption" align="center" color="secondary">
                                    <Typography variant="caption" align="center" color="textPrimary">
                                        Odobren vam je maksimalni mjesečni anuitet od {response?.maxAnnuityAmount} EUR.
                                    </Typography>
                                    <Typography variant="h6" align="center" color="textPrimary">
                                        Mjesečni anuitet
                                    </Typography>
                                    <Typography variant="h4" align="center" color="secondary">
                                        {monthlyInstallment}
                                    </Typography>
                                </Typography>
                            </FormGroup>
                            <Grid container direction="column">
                                {(calculateMonthlyInstallment(
                                    (watchAmount as number) ?? interest?.minFinancingAmount,
                                    (interest?.ktaWithoutStatusInBankTo as number) ?? 0,
                                    (watchInstallments as number) ?? response?.apiProduct.minFundingDeadline
                                ).toFixed(2) as unknown as number) > response?.maxAnnuityAmount && (
                                    <Typography className={styles.errorText}>Mjesečni anuitet prelazi maksimalnu granicu</Typography>
                                )}
                                <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                                    <Button disabled={monthlyInstallment > response?.maxAnnuityAmount} onClick={handleSubmit(submitForm)}>
                                        Pošalji
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UpdateLoan;
