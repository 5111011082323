import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/home';
import IdentyumRedirect from './components/identyumIdentifications/identyumRedirect';
import Psd2Redirect from './components/psd2/psd2Redirect';

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif !important',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: '600',
                },
            },
        },
    },
    palette: {
        primary: { main: '#987c64' },
        secondary: { main: '#000' },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/psd2-redirect">
                        <Psd2Redirect />
                    </Route>
                    <Route path="/identyum-redirect">
                        <IdentyumRedirect />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

export default App;
