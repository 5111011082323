import { Configuration as AmlConfiguration, KentAmlQuestionnaireControllerApi } from 'kent-aml-questionnaire-api';
import { Configuration as IdentyumConfiguration, KentIdentyumControllerApi } from 'kent-identyum-service-api';
import { Configuration as Psd2Configuration, Psd2ServiceControllerApi } from 'kent-psd2-service-api';
import { Configuration as UwtConfiguration, ProductCatalogueReaderControllerApi } from 'uwt-service-api';

export const amlApiController = new KentAmlQuestionnaireControllerApi(
    new AmlConfiguration({ basePath: process.env.REACT_APP_AML_QUESTIONNAIRE, baseOptions: { withCredentials: true } })
);

export const identyumApiController = new KentIdentyumControllerApi(
    new IdentyumConfiguration({ basePath: process.env.REACT_APP_IDENTYUM_SERVICE, baseOptions: { withCredentials: true } })
);

export const psd2ApiController = new Psd2ServiceControllerApi(
    new Psd2Configuration({ basePath: process.env.REACT_APP_PSD2_SERVICE, baseOptions: { withCredentials: true } })
);

export const uwtApiController = new ProductCatalogueReaderControllerApi(
    new UwtConfiguration({ basePath: process.env.REACT_APP_PRODUCTS_BASE_URL, baseOptions: { withCredentials: true } })
);
