import { useEffect, useState } from 'react';
import Loader from '../loader';
import { psd2ApiController } from '../../api/apis';
import { AuthorizationCodeRequestErrorEnum } from 'kent-psd2-service-api';
import { Grid, AppBar, Toolbar, Typography } from '@mui/material';
import Notification from '../notification';
import { INotificationStatus } from '../../lib/interfaces/interfaces';
import styles from './style.module.scss';

const Psd2Redirect = () => {
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [notificationStatus, setNotificationStatus] = useState<INotificationStatus>({ show: false });

    const handleError = (error?: number) => {
        if (error === 400) {
            window.location.href = '/';
            return;
        }

        setNotificationStatus({
            show: true,
            message: 'Dogodila se neočekivana greška.',
            showRetryButton: true,
        });
    };

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const error = urlParams.get('error');
        psd2ApiController
            .result({ code: code === null ? undefined : code, error: error === null ? undefined : AuthorizationCodeRequestErrorEnum[error] })
            .then(() => {
                setShowLoader(false);
                window.location.href = '/';
            })
            .catch((error) => {
                setShowLoader(false);
                handleError();
            });
    }, []);

    return (
        <div className={styles.rootComp}>
            <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.rootComp}>
                <AppBar position="static" color="secondary">
                    <Toolbar>
                        <Typography className="title">KENT Loan Portal</Typography>
                    </Toolbar>
                </AppBar>
                <Loader appear={showLoader} />
                <div className={styles.redirectComp}>
                    <div className="app-container shadow-lg d-flex flex-column position-relative">
                        <div className={`${styles.headerComp} border-bottom`}>PSD2 Servis</div>
                        <div className={styles.consentsBody}>
                            {notificationStatus.show && <Notification status={notificationStatus} onTryAgain={() => (window.location.href = '/')} />}
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    );
};

export default Psd2Redirect;
