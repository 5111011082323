import { Button, ButtonGroup, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { INotificationStatus } from '../../lib/interfaces/interfaces';
import styles from './style.module.scss';

type Props = {
    status: INotificationStatus;
    onTryAgain?: () => void;
};

const Notification: FC<PropsWithChildren<Props>> = ({ status, onTryAgain }) => {
    return (
        <div className={styles.root}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>
                    <span>ERROR</span>
                </div>
                <div className={styles.body}>
                    <Typography variant="h5" align="center" color="red">
                        {status.message}
                    </Typography>
                    {status.showRetryButton && (
                        <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                            <Button onClick={() => (onTryAgain ? onTryAgain() : window.location.reload())}>Pokušaj ponovno</Button>
                        </ButtonGroup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Notification;
