import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { identyumApiController } from '../../api/apis';
import Loader from '../loader';
import styles from './style.module.scss';

type Props = {
    onError: () => void;
};

const IdentyumIdentifications: FC<PropsWithChildren<Props>> = ({ onError }) => {
    const [showLoader, setShowLoader] = useState<boolean>(true);

    useEffect(() => {
        identyumApiController
            .start({ redirectUri: `${process.env.REACT_APP_REDIRECT_URI}/identyum-redirect` })
            .then((response) => {
                window.location.href = response.data.redirectUri;
            })
            .catch(() => {
                setShowLoader(false);
                onError();
            });
    }, [onError]);

    return (
        <div className={styles.rootComp}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>Identyum Identifications</div>
                <Loader appear={showLoader} />
            </div>
        </div>
    );
};

export default IdentyumIdentifications;
