export const CookiesIcon = () => {
    return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 464 464">
            <path
                fill="#B97850"
                d="M455.996,225.872C443.563,230.438,429.943,232,416,232c-6.533,0-12.907-0.619-19.195-1.51
				c-0.594,0.696-1.136,1.434-1.891,2.003l-13.546,10.226c-4.394,3.317-10.418,3.45-14.955,0.33L347.8,230.248
				c-5.245-3.607-6.63-8.546-3.8-14.248l0,0c0.663-1.336,1.224-4.047-1.46-5.711C304.553,186.743,280,144.013,280,96
				c0-27.542,8.271-53.104,22.364-74.504c2.944-4.47,0.578-10.452-4.554-11.971c-24.286-7.189-50.214-10.547-77.121-9.252
				C103.176,5.925,6.965,101.163,0.376,218.628C-7.153,352.824,99.441,464,232,464c128.13,0,232-103.87,232-232
				C464,229.049,462,223.667,455.996,225.872z"
            />
            <path
                fill="#A5694B"
                d="M96,232C96,120.33,174.907,27.113,280,5c-15.491-3.259-31.54-5-48-5C103.87,0,0,103.869,0,232
				c0,128.13,103.87,232,232,232c16.46,0,32.509-1.741,48-5C174.907,436.886,96,343.67,96,232z"
            />
            <path
                fill="#734B46"
                d="M192.5,224.242l-7.965,23.895c-2.518,7.554,0.902,15.81,8.024,19.37l25.274,12.637
				c6.16,3.08,13.599,1.873,18.469-2.997l15.012-15.012c5.191-5.191,6.184-13.25,2.406-19.546l-12.912-21.52
				c-2.892-4.819-8.1-7.768-13.72-7.768h-19.409C200.792,213.301,194.678,217.708,192.5,224.242z"
            />
            <path
                fill="#734B46"
                d="M104.018,120.196l7.57,24.023c2.393,7.594,10.006,12.273,17.862,10.979l27.881-4.595
				c6.795-1.12,12.111-6.463,13.198-13.263l3.349-20.964c1.158-7.25-2.767-14.358-9.519-17.24l-23.082-9.851
				c-5.169-2.206-11.117-1.538-15.668,1.759l-15.717,11.387C104.314,106.472,101.948,113.628,104.018,120.196z"
            />
            <path
                fill="#734B46"
                d="M274.737,96.437l-20.25,14.978c-6.402,4.735-8.348,13.456-4.566,20.463l13.421,24.866
				c3.271,6.06,10.053,9.348,16.837,8.161l20.913-3.658c7.232-1.265,12.675-7.29,13.202-14.613l1.8-25.031
				c0.403-5.606-2.164-11.012-6.764-14.242l-15.884-11.154C287.811,92.249,280.274,92.342,274.737,96.437z"
            />
            <path
                fill="#734B46"
                d="M63.081,254.065l5.278,19.432c1.669,6.143,7.6,10.124,13.917,9.341l22.419-2.779
				c5.464-0.677,9.882-4.775,10.967-10.173l3.346-16.64c1.157-5.754-1.751-11.558-7.052-14.076l-18.123-8.608
				c-4.059-1.928-8.831-1.585-12.572,0.904l-12.919,8.594C63.757,243.112,61.638,248.752,63.081,254.065z"
            />
            <path
                fill="#734B46"
                d="M344.349,301.96l-24.542-5.665c-7.758-1.791-15.653,2.395-18.523,9.823l-10.186,26.357
				c-2.483,6.424-0.576,13.716,4.733,18.102l16.367,13.522c5.66,4.676,13.776,4.9,19.686,0.544l20.2-14.892
				c4.524-3.335,6.966-8.799,6.433-14.394l-1.839-19.322C356.026,309.179,351.059,303.51,344.349,301.96z"
            />
            <path
                fill="#734B46"
                d="M196.814,368.722l-13.287-15.089c-4.2-4.77-11.269-5.726-16.585-2.242l-18.866,12.362
				c-4.598,3.013-6.752,8.63-5.348,13.945l4.329,16.384c1.497,5.666,6.658,9.586,12.518,9.508l20.03-0.267
				c4.486-0.06,8.611-2.469,10.868-6.346l7.793-13.39C201.032,378.836,200.446,372.848,196.814,368.722z"
            />
            <path
                fill="#644141"
                d="M128.955,144.219l-7.57-24.023c-2.07-6.568,0.296-13.725,5.873-17.766l15.717-11.387
				c0.333-0.242,0.719-0.365,1.066-0.579l-2.766-1.181c-5.169-2.205-11.117-1.537-15.668,1.76L109.89,102.43
				c-5.577,4.041-7.943,11.197-5.873,17.766l7.57,24.023c2.393,7.594,10.006,12.273,17.862,10.979l8.046-1.326
				C133.541,152.032,130.349,148.645,128.955,144.219z"
            />
            <path
                fill="#644141"
                d="M280.709,156.745l-13.421-24.866c-3.782-7.007-1.836-15.729,4.565-20.464l20.25-14.977
				c0.272-0.202,0.598-0.275,0.879-0.457c-5.584-3.654-12.851-3.533-18.247,0.457l-20.25,14.977
				c-6.401,4.735-8.348,13.457-4.565,20.464l13.421,24.866c3.271,6.061,10.053,9.348,16.837,8.161l7.689-1.345
				C284.893,162.131,282.355,159.793,280.709,156.745z"
            />
            <path
                fill="#644141"
                d="M329.565,364.099l-16.367-13.522c-5.309-4.387-7.216-11.678-4.733-18.102l10.186-26.357
				c1.437-3.717,4.132-6.621,7.438-8.373l-6.282-1.449c-7.759-1.791-15.653,2.395-18.523,9.822l-10.186,26.357
				c-2.483,6.424-0.576,13.715,4.733,18.102l16.367,13.522c5.442,4.497,13.113,4.765,18.952,0.914
				C330.633,364.678,330.049,364.498,329.565,364.099z"
            />
            <path
                fill="#644141"
                d="M209.926,267.506c-7.122-3.561-10.542-11.816-8.024-19.369l7.965-23.896
				c2.178-6.534,8.292-10.94,15.179-10.94h-17.367c-6.887,0-13.001,4.406-15.179,10.94l-7.965,23.896
				c-2.518,7.553,0.901,15.809,8.023,19.369l25.273,12.638c5.065,2.532,10.918,2.022,15.574-0.896L209.926,267.506z"
            />
            <path
                fill="#644141"
                d="M85.726,273.497l-5.278-19.433c-1.443-5.313,0.675-10.953,5.26-14.003l11.715-7.792l-3.59-1.705
				c-4.059-1.928-8.831-1.584-12.572,0.904l-12.919,8.593c-4.584,3.05-6.703,8.69-5.26,14.003l5.278,19.433
				c1.668,6.144,7.6,10.124,13.917,9.342l10.434-1.294C89.416,279.994,86.74,277.232,85.726,273.497z"
            />
            <path
                fill="#644141"
                d="M164.424,394.081l-4.329-16.384c-1.404-5.315,0.75-10.932,5.348-13.945l16.886-11.064
				c-4.259-3.792-10.511-4.492-15.387-1.297l-18.866,12.361c-4.598,3.014-6.752,8.63-5.348,13.945l4.329,16.384
				c1.497,5.666,6.658,9.587,12.518,9.509l15.995-0.214C170.31,402.864,165.799,399.287,164.424,394.081z"
            />
            <path
                fill="#C98850"
                d="M216,176L216,176c-4.418,0-8-3.582-8-8v-16c0-4.418,3.582-8,8-8l0,0c4.418,0,8,3.582,8,8v16
				C224,172.418,220.418,176,216,176z"
            />
            <path
                fill="#925F4A"
                d="M147.314,211.314L147.314,211.314c-3.124,3.124-8.19,3.124-11.314,0L124.686,200
				c-3.124-3.124-3.124-8.189,0-11.314l0,0c3.124-3.124,8.19-3.124,11.314,0L147.314,200
				C150.438,203.124,150.438,208.19,147.314,211.314z"
            />
            <path
                fill="#C98850"
                d="M146.121,318.881L146.121,318.881c-4.109-1.623-6.124-6.271-4.501-10.38l5.879-14.881
				c1.623-4.109,6.271-6.124,10.38-4.501l0,0c4.109,1.623,6.124,6.271,4.501,10.38l-5.879,14.881
				C154.878,318.489,150.23,320.504,146.121,318.881z"
            />
            <path
                fill="#925F4A"
                d="M240.517,341.969L240.517,341.969c-4.297,1.03-8.614-1.619-9.644-5.916l-3.728-15.56
				c-1.03-4.297,1.619-8.614,5.916-9.644l0,0c4.297-1.03,8.614,1.619,9.644,5.916l3.728,15.56
				C247.463,336.621,244.814,340.939,240.517,341.969z"
            />
            <path
                fill="#925F4A"
                d="M264.596,411.022L264.596,411.022c-1.67-4.091,0.292-8.76,4.383-10.43l14.813-6.048
				c4.091-1.67,8.76,0.292,10.43,4.383l0,0c1.67,4.091-0.292,8.76-4.383,10.43l-14.813,6.048
				C270.935,417.074,266.266,415.112,264.596,411.022z"
            />
            <path
                fill="#925F4A"
                d="M217.877,64.223L217.877,64.223c1.037-4.295,5.359-6.936,9.654-5.9l15.553,3.754
				c4.295,1.037,6.936,5.359,5.9,9.654l0,0c-1.037,4.295-5.359,6.936-9.654,5.9l-15.553-3.754
				C219.482,72.84,216.84,68.518,217.877,64.223z"
            />
            <path
                fill="#925F4A"
                d="M71.932,350.606L71.932,350.606c-4.161-1.485-6.331-6.062-4.846-10.223l5.377-15.069
				c1.485-4.161,6.062-6.331,10.223-4.846l0,0c4.161,1.485,6.331,6.062,4.846,10.223l-5.377,15.069
				C80.67,349.921,76.093,352.091,71.932,350.606z"
            />
            <path
                fill="#C98850"
                d="M60.445,162.331L60.445,162.331c4.293-1.043,8.619,1.592,9.662,5.886l3.776,15.548
				c1.043,4.293-1.592,8.619-5.886,9.662l0,0c-4.293,1.043-8.619-1.592-9.662-5.886l-3.776-15.548
				C53.517,167.7,56.152,163.374,60.445,162.331z"
            />
            <path
                fill="#925F4A"
                d="M284.686,235.314L284.686,235.314c-3.124-3.124-3.124-8.19,0-11.314L296,212.686
				c3.124-3.124,8.19-3.124,11.314,0l0,0c3.124,3.124,3.124,8.189,0,11.314L296,235.314
				C292.876,238.438,287.81,238.438,284.686,235.314z"
            />
            <path
                fill="#925F4A"
                d="M391.029,266.343L391.029,266.343c3.124-3.124,8.19-3.124,11.314,0l11.314,11.314
				c3.124,3.124,3.124,8.19,0,11.314l0,0c-3.124,3.124-8.19,3.124-11.314,0l-11.314-11.314
				C387.905,274.533,387.905,269.467,391.029,266.343z"
            />
            <path
                fill="#C98850"
                d="M393.125,329.8L393.125,329.8c4.087,1.68,6.038,6.354,4.358,10.441l-6.082,14.799
				c-1.68,4.087-6.354,6.038-10.44,4.358l0,0c-4.087-1.68-6.038-6.354-4.358-10.441l6.082-14.799
				C384.364,330.071,389.039,328.12,393.125,329.8z"
            />
            <path
                fill="#B97850"
                d="M384.156,134.417l5.43,27.151c0.847,4.234,4.901,7.032,9.16,6.322l32.784-5.464
				c4.233-0.706,7.155-4.625,6.623-8.883l-5.442-43.535c-0.738-5.901-7.428-8.963-12.376-5.664l-32.772,21.848
				C384.865,127.99,383.52,131.238,384.156,134.417z"
            />
            <path
                fill="#B97850"
                d="M359.592,100.626l57.52-25.565c3.869-1.719,5.728-6.155,4.242-10.119l-18.646-49.724
				c-1.511-4.029-5.938-6.141-10.02-4.78L355.52,22.827c-2.232,0.744-4.024,2.433-4.898,4.618l-13.434,33.584
				c-0.763,1.907-0.763,4.035,0,5.942l11.726,29.315C350.599,100.499,355.446,102.468,359.592,100.626z"
            />
            <path
                fill="#A5694B"
                d="M349.766,136h8.475c5.048,0,8.835,4.619,7.845,9.569l-3.743,18.717
				c-1.117,5.584-7.544,8.246-12.282,5.087l-8.976-5.984c-3.002-2.001-4.293-5.764-3.152-9.186l4.244-12.733
				C343.266,138.203,346.323,136,349.766,136z"
            />
            <path
                fill="#A5694B"
                d="M376,200v1.442c0,3.813,2.692,7.097,6.431,7.845l24,4.8c4.95,0.99,9.569-2.796,9.569-7.845V200
				c0-4.418-3.582-8-8-8h-24C379.582,192,376,195.582,376,200z"
            />
            <path
                fill="#A5694B"
                d="M364.298,90.969l-11.726-29.315c-0.763-1.907-0.763-4.035,0-5.942l13.434-33.584
				c0.768-1.92,2.319-3.34,4.181-4.189l-14.667,4.889c-2.232,0.744-4.024,2.434-4.898,4.619l-13.434,33.584
				c-0.763,1.906-0.763,4.034,0,5.941l11.726,29.316c1.685,4.211,6.531,6.182,10.677,4.339l10.95-4.866
				C367.845,95.342,365.391,93.699,364.298,90.969z"
            />
            <path
                fill="#A5694B"
                d="M403.402,159.984l-6.767-33.833c-0.478-2.388,0.031-4.757,1.176-6.792l-10.249,6.832
				c-2.698,1.799-4.043,5.047-3.407,8.226l5.43,27.151c0.847,4.233,4.901,7.031,9.16,6.322l9.089-1.516
				C405.63,164.964,403.955,162.746,403.402,159.984z"
            />
        </svg>
    );
};

export const InfoIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#fff"
            className="bi bi-info-circle"
            viewBox="0 0 16 16"
            style={{ marginRight: '1rem' }}>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg>
    );
};
