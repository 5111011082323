import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Product } from 'uwt-service-api';
import Slider, { SliderProps } from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';
import Loader from '../loader';
import styles from './style.module.scss';

type Props = {
    onSuccess: () => void;
    onError: () => void;
};

const SuccessSlider = styled(Slider)<SliderProps>(() => ({
    color: '#987c64',
    '& .MuiSlider-thumb': {
        '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${alpha('#987c64', 0.16)}`,
        },
        '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${alpha('#987c64', 0.16)}`,
        },
    },
}));

const LoanConfigurator: FC<PropsWithChildren<Props>> = ({ onSuccess, onError }) => {
    const { control, handleSubmit, watch } = useForm();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [productList, setProductList] = useState<Array<Product>>();
    const watchProduct = watch('productId');
    const watchAmount = watch('amount');
    const watchInstallments = watch('repaymentDeadline');

    useEffect(() => {
        setShowLoader(true);
        axios
            .get('https://apis.kent.identyum.com/uwt-service/api/v1/public/products', {
                withCredentials: true,
            })
            .then((response) => {
                setShowLoader(false);
                setProductList(response.data.products);
            })
            .catch(() => {
                setShowLoader(false);
                onError();
            });
    }, [onError]);

    const interest = productList && productList?.find((product) => product.id === watchProduct)?.interestRates?.find((currency) => currency.currency === 'EUR');

    const calculateMonthlyInstallment = (loan: number, interest: number, installmentsNr: number) => {
        const monthInterest = interest / 12 / 100;
        return loan * ((monthInterest * (1 + monthInterest) ** installmentsNr) / ((1 + monthInterest) ** installmentsNr - 1));
    };

    const submitForm = (data) => {
        setShowLoader(true);
        axios
            .post('https://apis.kent.identyum.com/uwt-service/api/v1/public/loan', data, {
                withCredentials: true,
            })
            .then(() => {
                setShowLoader(false);
                onSuccess();
            })
            .catch(() => {
                setShowLoader(false);
                onError();
            });
    };

    return (
        <div className={styles.root}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>
                    <span>Loan Configurator</span>
                </div>
                <Loader appear={showLoader} />
                <div className={styles.body}>
                    <FormGroup>
                        <FormControl variant="outlined" className={styles.inputMargin}>
                            <TextField select label="SREDSTVO PLAĆANJA" defaultValue="kent">
                                <MenuItem value="kent">Kentbank</MenuItem>
                            </TextField>
                        </FormControl>
                        <Controller
                            name="productId"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <FormControl variant="outlined" className={styles.inputMargin}>
                                    <TextField select label="PROIZVOD" value={value ?? ''} onChange={onChange} error={!!error}>
                                        {productList?.map((product) => (
                                            <MenuItem value={product.id} key={product.id}>
                                                {product.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {error && <FormHelperText style={{ color: '#f44336' }}>Potrebno odabrati proizvod.</FormHelperText>}
                                </FormControl>
                            )}
                            rules={{ required: true }}
                        />
                        {watchProduct && (
                            <>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    className={styles.inputMargin}
                                    style={{ marginBottom: 0 }}>
                                    <>
                                        <TextField
                                            className={styles.inputMargin}
                                            style={{ width: '45%' }}
                                            label="MIN ROK OTPLATE"
                                            variant="outlined"
                                            value={`${productList?.find((product) => product.id === watchProduct)?.minFundingDeadline}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        <TextField
                                            className={styles.inputMargin}
                                            style={{ width: '45%' }}
                                            label="MAX ROK OTPLATE"
                                            variant="outlined"
                                            value={`${productList?.find((product) => product.id === watchProduct)?.maxFundingDeadline}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </>
                                </Grid>
                                <Controller
                                    name="isConsumer"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className={styles.inputMargin}>
                                            <Grid container alignItems="center">
                                                <Checkbox checked={value} onChange={onChange} />
                                                <label>Želim postati / jesam klijent banke</label>
                                            </Grid>
                                            <FormHelperText className={styles.errorText}>{error?.message}</FormHelperText>
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="amount"
                                    control={control}
                                    defaultValue={interest?.minFinancingAmount}
                                    render={({ field: { onChange, value } }) => (
                                        <div className={styles.sliderDivs}>
                                            <span className={styles.sliderTextOne}>Iznos</span>
                                            <SuccessSlider
                                                className={styles.slider}
                                                value={value as number}
                                                defaultValue={interest?.minFinancingAmount}
                                                max={interest?.maxFinancingAmount}
                                                min={interest?.minFinancingAmount}
                                                step={100}
                                                onChange={(_event: any, value) => onChange(value as number)}
                                                valueLabelDisplay="auto"
                                            />
                                            <span>{watchAmount ?? interest?.minFinancingAmount} EUR</span>
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="repaymentDeadline"
                                    control={control}
                                    defaultValue={productList?.find((product) => product.id === watchProduct)?.minFundingDeadline}
                                    render={({ field: { onChange, value } }) => (
                                        <div className={styles.sliderDivs}>
                                            <span className={styles.sliderTextOne}>Rate</span>
                                            <SuccessSlider
                                                className={styles.slider}
                                                value={value as number}
                                                defaultValue={productList?.find((product) => product.id === watchProduct)?.minFundingDeadline}
                                                max={productList?.find((product) => product.id === watchProduct)?.maxFundingDeadline}
                                                min={productList?.find((product) => product.id === watchProduct)?.minFundingDeadline}
                                                step={1}
                                                onChange={(_event: any, value) => onChange(value as number)}
                                                valueLabelDisplay="auto"
                                            />
                                            <span>
                                                {watchInstallments ?? productList?.find((product) => product.id === watchProduct)?.minFundingDeadline} mj.
                                            </span>
                                        </div>
                                    )}
                                />
                            </>
                        )}

                        {watchProduct &&
                            productList?.find((product) => product.id === watchProduct)?.relatedDocuments &&
                            productList?.find((product) => product.id === watchProduct)?.relatedDocuments?.length !== 0 && (
                                <>
                                    POTREBNI DOKUMENTI:
                                    <ul className={styles.inputMargin}>
                                        {productList
                                            .find((product) => product.id === watchProduct)
                                            ?.relatedDocuments.map((document, i) => (
                                                <li key={i}>
                                                    <Typography>{document}</Typography>
                                                </li>
                                            ))}
                                    </ul>
                                </>
                            )}
                        {watchProduct && interest && (
                            <Typography style={{ marginBottom: 20 }} variant="caption" align="center" color="secondary">
                                <Typography variant="h6" align="center" color="textPrimary">
                                    Mjesečni anuitet
                                </Typography>
                                <Typography variant="h4" align="center" color="secondary">
                                    {`*${calculateMonthlyInstallment(
                                        (watchAmount as number) ?? interest?.minFinancingAmount,
                                        (interest.ktaWithStatusInBankTo as number) ?? 0,
                                        (watchInstallments as number) ?? productList?.find((product) => product.id === watchProduct)?.minFundingDeadline
                                    ).toFixed(2)}/${calculateMonthlyInstallment(
                                        (watchAmount as number) ?? interest?.minFinancingAmount,
                                        (interest.ktaWithoutStatusInBankTo as number) ?? 0,
                                        (watchInstallments as number) ?? productList?.find((product) => product.id === watchProduct)?.minFundingDeadline
                                    ).toFixed(2)}`}
                                </Typography>
                            </Typography>
                        )}
                        {interest && (
                            <Card variant="outlined">
                                <CardContent>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">Vrsta kamate</TableCell>
                                                    <TableCell align="right">{interest.interestRateType}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">Minimalni iznos kredita</TableCell>
                                                    <TableCell align="right">{interest?.minFinancingAmount?.toLocaleString('de-DE')}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">Maksimalni iznos kredita</TableCell>
                                                    <TableCell align="right">{interest?.maxFinancingAmount?.toLocaleString('de-DE')}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">Kamatna stopa</TableCell>
                                                    <TableCell align="right">
                                                        {`*${interest.ktaWithStatusInBankTo}/${interest.ktaWithoutStatusInBankTo}`}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="right">
                                                        <Typography
                                                            align="right"
                                                            style={{
                                                                fontStyle: 'italic',
                                                            }}>
                                                            *kamata za klijenta banke/kamata za ostale
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        )}
                    </FormGroup>
                    <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                        <Button onClick={handleSubmit(submitForm)}>Pošalji</Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

export default LoanConfigurator;
