import { FormGroup, TextField, ButtonGroup, Button } from '@mui/material';
import { FC, PropsWithChildren, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { psd2ApiController } from '../../api/apis';
import Loader from '../loader';
import IBAN from 'iban';
// import { SwitchCamera, AddAPhoto, ArrowBack } from '@mui/icons-material';
// import axios from 'axios';
// import CameraComponent, { ICameraComponent } from '../cameraComponent';
import styles from './style.module.scss';

type Props = {
    onError: () => void;
    onSuccess: () => void;
};

const Psd2Form: FC<PropsWithChildren<Props>> = ({ onSuccess, onError }) => {
    const { control, handleSubmit } = useForm();
    // const cameraComponentRef = useRef<ICameraComponent>(null);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    // const [componentState, setComponentState] = useState(0);
    // const [bank, setBank] = useState('');
    // const [view, setView] = useState<string>('start');
    // const [mirrored, setIsMirrored] = useState(false);
    // const [showButtons, setShowButtons] = useState(true);

    // let imageSendingInterval;
    // let timesRun = 0;

    // const tryAgain = () => {
    //     setView('camera');
    // };

    // const makePhoto = () => {
    //     timesRun = 0;
    //     cameraComponentRef.current!.startCapturing();
    //     setShowButtons(false);
    //     imageSendingInterval = setInterval(() => {
    //         getPhoto();
    //     }, 3000);
    // };

    // const getPhoto = async () => {
    //     if (cameraComponentRef.current) {
    //         timesRun += 1;

    //         const imageSrc = await cameraComponentRef.current.getScreenshot();

    //         if (imageSrc === null) {
    //             return;
    //         }

    //         submitPhoto(imageSrc);
    //     }
    // };

    // const submitPhoto = (imageSrc: string) => {
    //     axios
    //         .post(
    //             `${process.env.REACT_APP_SIMPLE_ID_SCANNER}/api/v1/scan`,
    //             {
    //                 image: imageSrc,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer `,
    //                 },
    //             }
    //         )
    //         .then((response) => {
    //             cameraComponentRef.current?.stopCamera();
    //             setShowButtons(true);

    //             onSuccess();
    //             clearInterval(imageSendingInterval);
    //         })
    //         .catch((err) => {
    //             const responseStatus = err.response.status;
    //             if (responseStatus === 422 && err.response.data.type === 'WRONG_DOCUMENT') {
    //                 cameraComponentRef.current?.stopCamera();
    //                 clearInterval(imageSendingInterval);
    //                 setShowButtons(true);
    //                 setView('wrong_document_scanned');
    //                 return;
    //             }
    //             if (timesRun >= 5) {
    //                 cameraComponentRef.current?.stopCamera();
    //                 clearInterval(imageSendingInterval);
    //                 setShowButtons(true);
    //                 setView('no_data_scanned');
    //             }
    //         });
    // };

    // const handleOnCancel = () => {
    //     clearInterval(imageSendingInterval);
    //     cameraComponentRef.current?.stopCamera();
    //     setTimeout(() => {
    //         setView('start');
    //     }, 1000);
    // };

    const submitForm = (data: any) => {
        setShowLoader(true);
        psd2ApiController
            .start({ iban: data.iban, redirectUri: `${process.env.REACT_APP_REDIRECT_URI}/psd2-redirect` })
            .then((response) => {
                setShowLoader(false);
                window.location.href = response.data.redirectUri;
            })
            .catch(() => {
                setShowLoader(false);
                onError();
            });
    };

    // const handleBankSelect = (event: ChangeEvent<HTMLInputElement>) => {
    //     setBank(event.target.value);
    //     setView('camera');
    // };

    return (
        <div className={styles.root}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>
                    {/* {componentState === 1 && <ArrowBack style={{ position: 'absolute', left: 10 }} onClick={() => setComponentState(0)}></ArrowBack>} */}
                    <span>PSD2 Servis</span>
                </div>
                <Loader appear={showLoader} />
                {/* <div className={styles.consentsBody} style={{ justifyContent: componentState === 0 ? 'space-between' : '' }}> */}
                <div className={styles.consentsBody}>
                    {/* {componentState === 0 && ( */}
                    <>
                        <FormGroup>
                            <Controller
                                name="iban"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        label="UNESITE IBAN"
                                        variant="outlined"
                                        value={value ? value : ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                rules={{ required: 'Potrebno unijeti IBAN.', validate: (value: string) => IBAN.isValid(value) || 'IBAN neispravan.' }}
                            />
                        </FormGroup>
                        <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                            <Button onClick={handleSubmit(submitForm)}>Pošalji</Button>
                            {/* <Button onClick={() => setComponentState(1)}>Skeniraj karticu</Button> */}
                        </ButtonGroup>
                    </>
                    {/* )} */}
                    {/* {componentState === 1 && (
                        <>
                            {view === 'start' && (
                                <FormControl variant="outlined">
                                    <TextField select label="ODABERITE BANKU" defaultValue={bank} name="bank" onChange={handleBankSelect}>
                                        <MenuItem value="erste">Erste Banka</MenuItem>
                                        <MenuItem value="pbz">Privredna Banka Zagreb</MenuItem>
                                        <MenuItem value="otp">OTP Banka</MenuItem>
                                    </TextField>
                                </FormControl>
                            )}
                            {view === 'no_data_scanned' && (
                                <div className={styles.errorComp}>
                                    <Typography variant="h5" color="secondary" className={styles.instructions} style={{ marginBottom: '2rem' }}>
                                        Neuspješno čitanje podataka. Pokušajte ponovno.
                                    </Typography>
                                    <Button variant="outlined" color="secondary" onClick={tryAgain}>
                                        Pokušaj ponovo
                                    </Button>
                                </div>
                            )}
                            {view === 'wrong_document_scanned' && (
                                <div className={styles.errorComp}>
                                    <Typography variant="h5" color="secondary" className={styles.instructions} style={{ marginBottom: '2rem' }}>
                                        Skeniran nepodržani dokument. Pokušajte ponovno.
                                    </Typography>
                                    <Button variant="outlined" color="secondary" onClick={tryAgain}>
                                        Pokušaj ponovo
                                    </Button>
                                </div>
                            )}
                            {view === 'camera' && (
                                <>
                                    <ButtonGroup
                                        style={{
                                            alignSelf: 'center',
                                        }}>
                                        <Button variant="outlined" color="primary" onClick={() => setIsMirrored(!mirrored)}>
                                            Zrcali kameru
                                            <SwitchCamera fontSize="large" style={{ marginLeft: '0.5rem' }} />
                                        </Button>
                                    </ButtonGroup>
                                    <br />
                                    <div className={styles.videoComp}>
                                        {!showButtons && <LinearProgress />}
                                        <CameraComponent ref={cameraComponentRef} mirrored={mirrored} captureFps={2} />
                                    </div>
                                    <ButtonGroup
                                        style={{
                                            alignSelf: 'center',
                                            margin: '2rem 0 0 0',
                                        }}>
                                        <Button variant="outlined" color="primary" onClick={makePhoto} disabled={!showButtons}>
                                            Snimi iskaznicu
                                            <AddAPhoto fontSize="large" style={{ marginLeft: '0.5rem' }} />
                                        </Button>
                                        <Button variant="outlined" color="primary" onClick={handleOnCancel}>
                                            Odustani
                                        </Button>
                                    </ButtonGroup>
                                    <Typography variant="h5" color="secondary" className={styles.instructions}>
                                        Slikajte IBAN kartice.
                                    </Typography>
                                </>
                            )}
                        </>
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default Psd2Form;
