import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import styles from './style.module.scss';

const Finish = () => {
    return (
        <div className={styles.root}>
            <div className="app-container shadow-lg d-flex flex-column position-relative">
                <div className={`${styles.header} border-bottom`}>
                    <span>Finished</span>
                </div>
                <div className={styles.body}>
                    <Typography textAlign="center" variant="h6">
                        Uspješno ste završili proces. Možete zatvoriti ovaj prozor.
                    </Typography>
                    <CheckCircleOutlineIcon color="success" className={styles.checkIcon} />
                </div>
            </div>
        </div>
    );
};

export default Finish;
